<template>
  <div>
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div class="m-card text-center pb-3">
              <h1 class="o-heading o-heading--xl">
                {{ $t("guideTutorial.message") }}
              </h1>
            </div>                    
          </div>
        </div>
      </div>
    </div>
  
    <div class="row no-gutters mt-5">
      <div class="col-10 offset-1 text-center">
        <p class="o-heading">Come mi registro?</p>
        <iframe title="vimeo-player" src="https://player.vimeo.com/video/694749755?h=2ac6f342bb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="640" height="360" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>

    <div class="row no-gutters mt-5">
      <div class="col-10 offset-1 text-center">
        <p class="o-heading">Come attivo la mia area referti e fatture?</p>
        <iframe title="vimeo-player" src="https://player.vimeo.com/video/694749744?h=0d75718ba2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="640" height="360" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>

    <div class="row no-gutters mt-5">
      <div class="col-10 offset-1 text-center">
        <p class="o-heading">Come funziona la mia area personale?</p>
        <iframe title="vimeo-player" src="https://player.vimeo.com/video/694749727?h=962d5addf9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="640" height="360" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>        

  </div>
</template>
<script>

export default {
  components: {},
  name: "guideTutorial",

  data() {
    return {
      message: "",
    };
  },

  methods: {
  }
};
</script>


